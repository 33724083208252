@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import 'animations';
body * {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  outline: 0;
}

body {
  width: 100%;
  height: 100%;
  list-style: none; 
  font-family: 'Castledown', sans-serif;
}

#wrapper { 
  width: 100%; 
  overflow: hidden;
}

.container { 
  text-align: left;
  max-width: 1140px; 
  margin: 0 auto; 
  padding-left: 15px!important;
  padding-right: 15px!important;
  width: 100%; 
  box-sizing:border-box; 
  -moz-box-sizing:border-box; 
  -webkit-box-sizing:border-box; 
  padding: 0;
}

a:focus{
  text-decoration: none;
}

/******MIXIN******/
@mixin prefixonprop($prop, $val...) {
    -webkit-#{$prop}: $val;
    -moz-#{$prop}: $val;
    -o-#{$prop}: $val;
    -ms-#{$prop}: $val;
    #{$prop}: $val;
}

@mixin prefixonvalue($prop, $val...) {
    #{$prop}: -webkit-#{$val};
    #{$prop}: -moz-#{$val};
    #{$prop}: -o-#{$val};
    #{$prop}: -ms-#{$val};
    #{$prop}: $val;
}

@mixin block { 
  margin: 0 auto; 
  width: 100%; 
  display: block;
}

@mixin flex_box{
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin clear_fix{
  content: "";
  display: block;
  clear: both;
}

$font-title: 'Gilroy';

h2{
  font-family: $font-title;
  font-size: 80px;
  letter-spacing: -1px;
  line-height: 0.9;
  font-weight: bold;
  .text-black{
    color: #1d1d1d;
    display: block;
  }
  .text-white{
    display: block;
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1px #1d1d1d;
    text-stroke: 1px #1d1d1d;
  }
  span + span{
    margin-top: 5px;
  }
}

h5{
  font-family: $font-title;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
}

h6{
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
}

p{
  font-family: $font-title;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #1d1d1d;
}

.button-black{
  display: block;
  clear: both;
  font-family: $font-title;
  background-color: #1d1d1d;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 21px 63px 19px 24px;
  position: relative;
  width: 200px;
  @include prefixonprop(transition, width .6s 0s cubic-bezier(.25,.1, 0,1), transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
  &:focus{
    color: #ffffff;
  }
  &:after{
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(../img/arrow-right.svg) no-repeat center center;
    @include prefixonprop(transform, rotate(-90deg));
    position: absolute;
    right: 24px;
    top: 18px;
    @include prefixonprop(transition, right .6s cubic-bezier(.25,.1, 0,1));
  }
  &:hover{
    text-decoration: none;
    color: #ffffff;
    width: 215px;
    transition-delay: 0s!important;
    @include prefixonprop(transition, width .6s cubic-bezier(.25,.1, 0,1));
    transition-origin: left;
    &:after{
      right: 20px;
      @include prefixonprop(transition, right .6s cubic-bezier(.25,.1, 0,1));
    }
  }
}

.bg{
  &.next{
    z-index: -1;
  }
   &.selected{
    z-index: 0;
    transform: scaleX(0);
    transition: transform 1.2s cubic-bezier(.25,.1, 0,1);
  }
}

.bg-change-color-G{
  display: block;
  position: absolute;
  z-index: -2;
  right: 0;
  width: 44vw;
  height: 577px;
  opacity: 1;
  background-color: #F1F1F1;
  transform-origin: left; 
}
.bg-change-color-Y{
  display: block;
  position: absolute;
  z-index: -4;
  right: 0;
  width: 44vw;
  height: 577px;
  background-color: #FDF6EA;
  opacity: 1;
  transform-origin: left; 
}
.bg-change-color-R{
  display: block;
  position: absolute;
  z-index: -3;
  right: 0;
  width: 44vw;
  height: 577px;
  background-color: #FEE8EF;
  opacity: 1;  
  transform-origin: left; 
}

/*********** 
   HEADER
************/
#wrapper_header {
  background-color: transparent;
  position: absolute;
  top: 12px;
  @include block;
  z-index: 22;
  #header{
    @include block;
    .container{
      padding: 36px 0;
      &:after {
        @include clear_fix;
      }
      .logo{
        margin:0 0;
        float: left;
        outline: 0;
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        opacity: 1;
        h1{
          text-indent: -9999px;
          height: 0;
        }
        img{
          display: block;
          width: 109px;
          height: auto;
        }
      }
      .link-right{
        float: right;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: bold;
        color: #1d1d1d;
        vertical-align: middle;
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        opacity: 1;
        padding: 0px 0 5px;
        cursor: pointer;
        &:before{
          content: "";
          width: 32px;
          height: 32px;
          display: inline-block;
          background: url(../img/icon-tel.svg) no-repeat center top;
          vertical-align: middle;
          margin-left: 16px;
          float: right;
          margin-top: -5px;
        }
        &:after{
          display: block;
          content: "";
          width: 0;
          height: 2px;
          background-color: #1d1d1d;
          @include prefixonprop(transition, width 0.6s cubic-bezier(.25,.1, 0,1));
        }
        &:hover{
          text-decoration: none;
          &:after{
            width: 116px;
            @include prefixonprop(transition, width 0.6s cubic-bezier(.25,.1, 0,1));
          }
        }
      }
    }
  }
}
/***************
  END HEADER
****************/
.hero {
  display: block;
  .container {
    display: block;
    padding: 183px 0 80px;
    @include flex-box;
    align-items: flex-top;
    .col-12{
      padding: 0;
      position: relative;
      z-index: 1;
      h2{
        max-width: 473px;
        span{
          opacity: 1;
          @include prefixonprop(transform, translateY(0));
          @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        }
        @for $i from 1 through 2{ 
          .item-#{$i}{
              transition-delay: 0.1s + ($i / 9);
          }
        }
        span + span{
          margin-top: 15px;
        }
      }
      >p{
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.78;
        letter-spacing: normal;
        max-width: 445px;
      }
    }
    .col-img {
      z-index: 0;
      position: relative;
      min-height: 780px;
      @include prefixonprop(order, 2);
      .card-container{
        background-color: #ffffff;
        position: absolute;
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        transition-delay: 1.8s;
        .img-container{
          display: block;
          padding: 24px;
          width: 255px;
          height: 255px;
          overflow: hidden;
          position: relative;  
          >div{
            position: absolute;
            z-index: 1;
            left: 24px;
            width: 207px;
            height: 207px;
            transform-origin: left;
            overflow: hidden;
            img{
              width: 207px;
              height: 207px;
              display: inline-block;
              position: relative;
            }
            &.image-1{
              z-index: -1;
            }
            &.image-2{
              z-index: 0;
            }
            &.image-3{
              z-index: 1;
            }
            &.next{
              z-index: 2;
            }
            &.selected{
              z-index: 3;
              width: 0;
              @include prefixonprop(transition, width 1.2s cubic-bezier(.25,.1, 0,1));
            }
          }
        }
        h6{
          padding: 0 16px 16px;
           transition-delay: 0s;
        }
        .card-title{
          height: 36px;
          overflow: hidden;
          h6{
            position: absolute;
            left: 0;
            z-index: 0;
            opacity: 0;

            &.selected{
              z-index: 2;
              transform: translateY(0);
              opacity: 1;
              transition: transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.5s ease-out;
            }    
          }
        }
        .footer-card{
          height: 38px;
          position: relative;
          overflow: hidden;
          display: block;
          @include flex_box;
          justify-content: space-between;
          border-top: 1px solid #e4e4e4;
          h6{
            padding-top: 8px;
            padding-bottom: 8px;
            position: absolute;
            left: 0;
            width: 50%;
            z-index: 0;
            opacity: 0;
            &.selected{
              z-index: 2;
              transform: translateY(0);
              opacity: 1;
              transition: transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.5s ease-out;
            }  
          }
          .price{
            position: relative;
            h6{
              left: 0;
            }
          }
          .likes{
            width: 50%;
            position: relative;
            &:before{
              content: "";
              width: 24px;
              height: 24px;
              display: inline-block;
              background: url(../img/icon-heart.svg) no-repeat center center;
              position: absolute;
              top: 7px;
              right: 33px;
            }
            h6{
              right: 0;
              left: auto;
              text-align: right;
            }
          }
        }
      }
      .block-top{
        top: 0;
        left: 0%;
        z-index: 3;
      }
      .block-right{
        top: 19%;
        right: 0%;
        z-index: 5;
        transition-delay: 1.9s;
        .likes{
          &:before{
            right: 40px!important;
          }
        }
      }
      .full-img-product{
        position: absolute;
        left: -70px;
        width: 445px;
        height: 500px;
        top: 46%;
        overflow: hidden;
        div{
          position: absolute;
          width: 445px;
          height: 100%;
          overflow: hidden;
          transform-origin: left;
           img{
            display: block;
            width: 445px;
            height: auto;
            position: relative;     
            transform-origin: center; 
            transform: scale(1.1,1.1);
            transition-delay: 1s;
            z-index: 1;
          }
          &:first-child{
            z-index: 1;
          }
          &:nth-child(2){
            z-index: 0;
          }
          &:last-child{
            z-index: -1;
          }
          &.next{
            z-index: 2;
            img{
              transform: scale(1,1);
              transition: transform 1.7s cubic-bezier(.25,.1, 0,1);
            }
          }
          &.selected{
            z-index: 3;
            width: 0;
            transition: width 1.2s cubic-bezier(.25,.1, 0,1);  
          }
          div:not(.selected) img, {
            transform: scale(1.1,1.1);
            
          }
        }     
      }
    }
    .content-left {
      @include prefixonprop(order, 1);
      p{
        margin-top: 40px;
        max-width: 445px;
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        transition-delay: 0.5s;
      }
    }
    .button-black{
      margin-top: 40px;
      width: 227px;
    }
  }
}

/***************
   END HERO
****************/
/***************
    BRANDS
****************/
#brands-container{
  @include block;
  .container{
    margin: 120px auto 38px;
    text-align: center;
    h5{
      i{
        opacity: 0.5;
        font-style: italic;
        font-weight: normal;
      }
    }
    .container-logos{
      @include flex_box;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      img{
        margin: 32px 0;
        max-width: 160px;
      }
    }
  }
}
/***************
   END BRANDS
****************/
/***************
   END 
****************/
.colums-module {
  display: block;
  margin-top: -29px;
  .container {
    padding: 50px 0;
    .row{
      display: block;
      @include flex_box;
      flex-direction: row;
      align-items: center;   
      .col-md-6{
        img{
          max-width: 255px;
          height: auto;
        }       
        p{
          max-width: 445px;
        }
        h2 + p{margin-top: 32px;}
        p + a{
          margin-top: 40px;
        }
      }
      .col-img{
        min-height: 788px;
        @include flex_box;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        .img-container{
          max-width: 255px;
          height: 284px;
          margin: 15px 0;
          width: 44.737%;
          position: relative;
          img{
            width: 255px;
            height: 284px;
            display: inline-block;
            position: relative;
          }
          >div{
            display: block;
            width: 255px;
            height: 284px;
            position: absolute;
            overflow: hidden;
            transform-origin: left;
            z-index: 1;
            &:first-child{
              z-index: 1;
            }
            &:nth-child(2){
              z-index: 0;
            }
            &:last-child{
              z-index: -1;
            }
            &.next{
              z-index: 2;
            }
            &.selected{
              z-index: 3;
              width: 0;
              transform-origin: left;
              @include prefixonprop(transition, width 1.2s cubic-bezier(.25,.1, 0,1));
            }
          }
          &.card-1{
            position: absolute;
            left: 4%;
            top: 50%; 
            @include prefixonprop(transform, translateY(-50%));
          }
        }
        .bg-color{
          display: block;
          height: 788px;
          width: 350px;
          background-color: #f9f9fa;
          margin: 0 auto;
          z-index: -1;
          position: absolute;
          left: 50%;
          @include prefixonprop(transform, translateX(-50%));
          transform-origin: top;
        }
        &.animBlock[data-position="up"]{
          .bg-color{
           @include prefixonprop(transform, scaleY(0) translateX(-50%));
          }
          .card-2, .card-3{
            opacity: 0;
            @include prefixonprop(transform, translateY(20%));
          }
          .card-1{
            opacity: 0;
            @include prefixonprop(transform, translateY(-30%));
          }
        }
        &.animBlock[data-position="up"].viewed{
          .bg-color{
           @include prefixonprop(transform, scaleY(1) translateX(-50%));
           @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1));
          }
          .card-2, .card-3{
            @include prefixonprop(transform, translateY(0));
            @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
            opacity: 1;
          }
          .card-1{
            @include prefixonprop(transform, translateY(-50%));
            @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
            opacity: 1;
          }
          @for $i from 1 through 4{ 
            .card-#{$i}{
                transition-delay: 0.1s + ($i / 8);
            }
          }
        }
      }
    }
  }
}
/***************
   END 
****************/

#customer-insights{
  .container{
    padding: 120px 0 116px;
    .row{
      .col-md-6{    
        min-height: auto!important; 
        p{
          max-width: 440px;
          position: relative;
          z-index: 4;
        }
        img:first-child{
          max-width: 488px;
          width: 100%;
          height: auto;
          display: block;
          position: relative;
          z-index: 1;
          margin-right: 0;
          margin-left: auto;
        }
        img:nth-child(2){
          max-width: 214px;
          width: 44%;
          height: auto;
          display: block;
          position: relative;
          z-index: 2;
          padding: 16px;
          background-color: #ffffff;
          top: -80px;
          left: -60px;
        }
        img:last-child{
          max-width: 562px;
          width: 100%;
          height: auto;
          display: block;
          position: absolute;
          z-index: 0;
          right: 30px;
          bottom: -20px;
        }
      }
    }
  }
}
/***************
   
****************/
/***************
    MEDIA
****************/
#media-container{
  @include block;
  .container{
    margin: 0px auto 140px;
    text-align: center;
    h5{
      i{
        opacity: 0.5;
        font-style: italic;
        font-weight: normal;
      }
    }
    .container-logos{
      @include flex_box;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      a{
        margin: 40px 0;
        max-width: 160px;
        img{
          max-width: 160px;
        }
      }
    }
  }
}
/***************
   END MEDIA
****************/
/***************
   CONTACT
****************/
#contact{
  .container{
    position: relative;
    padding: 0;
    .bg-black{
      display: block;
      background-color: #1d1d1d;
      max-width: 930px;
      height: 100%;
      width: 70%;
      top: 0;
      position: absolute;
      right: -13.3%;
      z-index: 1;
    }
    .col-12:last-child{
      min-height: 778px;
      .container-form{
        padding: 65px 0 80px;
        text-align: left;
        position: absolute;
        z-index: 2;
        @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
        &.hidden{
          @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
          z-index: 0;
          opacity: 0;
        }
      }
      .msj-submit{
        position: relative;
        text-align: left;
        z-index: 0;
        opacity: 0;
        padding: 65px 0 80px;
        left: 0;
        top: 0;
        @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
        h2{
          .text-black{
            color: #ffffff;
            display: block;
          }
          .text-white{
            -webkit-text-stroke: 1px #ffffff;
            text-stroke: 1px #ffffff;
            margin-top: 5px;
            display: block;
          }
        }
        p{
          color: #ffffff;
          margin-top: 24px;
        }
        &.show{
          @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
          opacity: 1;
          z-index: 4;
        }
      }
      h2{
        .text-black{
          color: #ffffff;
          display: block;
        }
        .text-white{
          -webkit-text-stroke: 1px #ffffff;
          text-stroke: 1px #ffffff;
          margin-top: 5px;
          display: block;
        }
      }
      p{
        color: #ffffff;
        margin-top: 24px;
      }
      form{
        display: block;
        max-width: 445px;
        .field{
          width: 100%;
          margin-top: 42px;
          position: relative;
          input::-webkit-input-placeholder{color: rgba(255,255,255,0.4);}
          input:-moz-placeholder{color: rgba(255,255,255,0.4);}
          input::-moz-placeholder{color: rgba(255,255,255,0.4);}
          input:-ms-input-placeholder{color: rgba(255,255,255,0.4);}
          input.error::-webkit-input-placeholder{color: #ff5e4d;}
          input.error:-moz-placeholder{color: #ff5e4d;}
          input.error::-moz-placeholder{color: #ff5e4d;}
          input.error:-ms-input-placeholder{color: #ff5e4d;}
          input{
            @include prefixonprop(appearance, none);
            background-color: rgba(255,255,255,0.1);
            width: 100%;
            height: 56px;
            border: 0;
            box-shadow: none;
            color: #ffffff;
            padding: 2px 24px 0;
            font-family: $font-title;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            :placeholder{
              color: rgba(255,255,255,0.8);
            }
            &.error{
              background-color: rgba(255,94,77,0.1);
            }
          }
          .button-black{
            background-color: #ffffff;
            border: 0;
            color: #1b1b1b;
            margin-top: 10px;
            &:after{
              background-image: url(../img/icon-arrow-black.svg);
              @include prefixonprop(transform, rotate(360deg));
            }
          }
          label.error{
            font-size: 14px;
            font-weight: normal;
            font-family: $font-title;
            color: #ff5e4d;
            position: absolute;
            top: 61px;
            left: 0;
          }
        }
      }
     
    }
    .col-img{
      min-height: auto;
      img{
        width: 100%;
        height: auto;
        max-width: 540px;
        z-index: -1;
        position: relative;
        top: -50px;
      }
    }
  }
}
/***************
  END FOOTER
****************/
footer{
  @include block;
  .container{
    text-align: center;
    padding: 39px 0 56px;
    &:after{
      @include clear_fix;
    }
    img{
      float: left;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      opacity: 0.24;
      margin: 0 24px 0 0;
    }
    >p{
      display: inline-block;
      font-family: $font-title;
      font-size: 18px;
      font-weight: normal;
      line-height: 40px;
      float: left;
      vertical-align: middle;
      opacity: 0.56;
    }
    .social{
      float: right;
      display: block;
      vertical-align: middle;
      margin-top: 10px;
      li{
        display: inline-block;
        a{
          display: block;
          outline: 0;
          width: 24px;
          height: 24px;
          opacity: 0.24;
          cursor: pointer;
          @include prefixonprop(transition, opacity 0.3s ease-out);
          &.twitter{
            background: url(../img/twitter.svg) no-repeat center;
          }
          &.in{
            background: url(../img/linked-in.svg) no-repeat center;
          }
          &.face{
            background: url(../img/icon-facebook.svg) no-repeat center;
          }
          &:hover{
            opacity: 1;
            @include prefixonprop(transition, opacity 0.3s ease-out);
          }
        }
      }
      li + li {margin-left: 10px;}
    }
  }
}

/***************
   RESPONSIVE
****************/

@media only screen and (max-width: 768px) {
  .row{flex-direction: column!important;}
  .bg-change-color-R, .bg-change-color-Y, .bg-change-color-G{width: 73vw; height: 860px;}

  .hero .container .col-12 {width: 100%!important;}
  .hero .container .col-12 h2{max-width: 600px;}
  .hero .container .col-img .block-top{top: -19%; right: 8%;left: auto;}
  .hero .container .col-img .block-right{left: 8%;top: 6%;right: auto;}
  .hero .container .col-img .full-img-product{height: 85.103%;top: 24%;left: auto;right: 11%;}

  .colums-module .container .row .col-12:first-child{width: 100%;}
  .colums-module .container .row .col-12:last-child{min-width: 540px;}

  #brands-container .container,#media-container .container{max-width: 560px; justify-content: center; margin: 0 auto 100px;}
  #brands-container .container h5, #brands-container .container img,
  #media-container .container h5, #media-container .container a{margin: 20px 30px;}
  #brands-container .container .container-logos img,
  #media-container .container .container-logos a{margin: 8px 0;}

  #discovery-brands .container .row .col-img{min-height: 640px; min-width: 78%;}
  #discovery-brands .container .row .col-img .bg-color{height: 470px;}
  #discovery-brands .container .row .col-md-6 p,
  #customer-insights .container .row .col-md-6 p{max-width: 100%;}

  #customer-insights .container .row .col-md-6 img{min-width: 107%; margin-top: 48px;}
  #customer-insights .container .row .col-md-6{min-width: 100%;min-height: auto;}
  #customer-insights .container .row .col-md-6 .container-animated{width: 100%;}
  #customer-insights .container .row .col-md-6 .container-animated img{min-width: auto;}
  #customer-insights .container .row .col-md-6 img:first-child {max-width: 541px;width: 85%;}
  #customer-insights .container .row .col-md-6 img:nth-child(2){max-width: 280px;position: absolute;top: 30%;left: 9%;width: 43%;}
  #customer-insights .container .row .col-md-6 img:last-child{max-width: 562px;position: relative;right: -18%;bottom: 0; margin-top: 0;}

  #contact{overflow: hidden;}
  #contact .container .col-12 .container-img{padding-top: 332px;}
  #contact .container .col-12 img{top: 0; left: -15px; position: absolute;}
  #contact .container .bg-black{max-width: auto;height: 100%;width: calc(100% - 15px);right: 0;top: 332px;}
  #contact .container .col-12:last-child .container-form,#contact .container .col-12:last-child .msj-submit{padding: 65px 32px 80px;}
  #contact .container .col-12:last-child {min-width: 100%;}
}
@media only screen and (max-width: 640px) {
  .bg-change-color-R, .bg-change-color-Y, .bg-change-color-G{width: 73vw; height: 920px;}

  .hero .container .content-left p{display: none;}
  .hero .container{padding-top: 135px;}
  .hero .container .col-img{min-height: auto;height: auto;}
  .hero .container .col-img .full-img-product{width: 259px;height: 291px;left: 0;right: auto;position: relative; top: 98px;}
  .hero .container .col-img .block-top{width: 158px; height: 191px;left: 0; position: relative; top: 70px; overflow: hidden;}
  .hero .container .col-img .block-right{width: 158px; height: 191px;right: 0;left: auto;top: 142px; overflow: hidden;}
  .hero .container .col-img .card-container .img-container {width: 158px;height: 127px; padding: 8px 24px;}
  .hero .container .col-img .card-container .img-container > div,
  .hero .container .col-img .card-container .img-container > div img{width: 110px;height: 110px;}
  .hero .container .col-img .card-container .card-title{overflow: hidden;height: 24px;}
  .hero .container .col-img .card-container .card-title h6{font-size: 12px;padding: 0 8px 10px;line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
    width: 152px;
    overflow: hidden;
    table-layout: fixed;
    display: table-cell;
  }
  .hero .container .col-img .card-container h6{padding: 0 8px 8px;}
  .hero .container .col-img .full-img-product div img{width: 259px;height: 291px;}
 
   #brands-container .container{ max-width: 360px; margin-top: 120px; margin-bottom: 50px;}
   #media-container .container{ max-width: 360px; margin-top: 0px;}

   .colums-module .container .row .col-md-6 p + a{margin-top: 40px;}
   #discovery-brands .container .row .col-img{min-height: auto; padding-top: 56px;}
   #discovery-brands .container .row .col-img .bg-color{width: 297px; min-height: 485px;}
   #discovery-brands .container .card-3,#customized-homepage .container .img-2,#customized-homepage .container .img-1{display: none;}
   #discovery-brands .container .row .col-img .card-1{
    position: relative;
    left: -152px;
    @include prefixonprop(transform, translateY(0));
    top: -4px;
    @include prefixonprop(order, 2);
  }
   .colums-module .container .row .col-img .card-2,
   .colums-module .container .row .col-img .card-1{width: 255px;height: 284px;margin: 0;}
   .colums-module .container .row .col-img .card-2{top: 12px; z-index: 0;}
   #customized-homepage .container{padding: 40px 15px;text-align: left;}
   #customized-homepage .container .img-mobile{margin-top: 56px;display: block!important;max-width: 362px;}
   #customer-insights .container .row .col-md-6 img:nth-child(2){left: 0;}
   #customer-insights .container .row .col-md-6 img:last-child{right: 0;}
   #customer-insights .container{padding: 100px 0;}
}

@media only screen and (max-width: 480px) {
  h2{
    font-size: 50px;
    line-height: 1.04;
    .text-white{
      margin-top: 0;
    }
  }
  p{
    font-size: 17px;
    line-height: 1.65;
  }
  .bg-change-color-R, .bg-change-color-Y, .bg-change-color-G{width: 73vw; height: 761px;}

  #wrapper_header #header .container .link-right:after{margin-left: 5px;}

  .colums-module .container{padding: 70px 0 30px;}

  #discovery-brands .container .row .col-img{min-width: 100%;}
  #discovery-brands .container .row .col-img .card-1{left: -90px;}
  #discovery-brands .container .row .col-md-6 h2 + p{margin-top: 20px;}

  #brands-container .container h5, #media-container .container h5{margin: 20px 20px;}
  #media-container .container a,
  #brands-container .container img{margin: 20px 20px; width: 50%;}

  #contact .container .bg-black{top: 23%;}
  #contact .container .col-12 .container-img{padding-top: 61%;}


  footer .container{padding: 30px 15px;}
  footer .container > p{
    font-size: 14px;
    max-width: 132px;
    text-align: left;
    margin-top: 5px!important;
    line-height: normal;
  }
 
}


